import React from "react"
import Layout from "../components/Layout/Layout"
import PageTransitionContainer from "../components/PageTransitionContainer"
import Form from "../components/Form"
import Seo from "../components/Seo"
import { sections } from "../data/form-refinance"

const Refinance = (props) => {
  return (
    <PageTransitionContainer>
      <Layout location={props.location} page="refinance">
        <Seo title="Refinance A Home | Ratelock" location={props.location} />
        <Form
          idToUse="refinance"
          label="Refinance Mortgage"
          sections={sections}
        />
      </Layout>
    </PageTransitionContainer>
  )
}

export default Refinance
