import React from "react"
import { formatCurrency } from "../utils/helper"
import {
  faMoneyBill,
  faHome,
  faGlobeAmericas
} from "@fortawesome/free-solid-svg-icons"

export const sections = [
  {
    name: "Your Purchase Cost",
    questions: [
      {
        label: "Which property are you planning on refinancing?",
        type: "options",
        buttons: ["Primary Home", "Investment Property", "Vacation Home"],
        iconButtons: [faHome, faMoneyBill, faGlobeAmericas]
      },
      {
        label: "What is the Current Estimated Value of the property?",
        type: "amount",
        stepHelp:
          "Please provide an estimate of the current market value of your property.",
        min: {
          amount: 100000,
          error: "Please enter a value between 100K - 5M."
        },
        max: {
          amount: 5000000,
          error: "Please enter a value between 100K - 5M."
        }
      },
      {
        label:
          "What is the Mortgage Balance on the current mortgage? How much do you currently carry?",
        type: "amount",
        min: {
          amount: 1,
          error: "Please enter a value above 0"
        },
        max: {
          prevFromIndex: 1,
          amount: (x, prev, prevOfPrev = 0) => 0.8 * prev,
          error: (x) =>
            `Your remaining Mortgage Balance must be less than 80% of the current estimated property value in order for you to qualify for refinancing. (Less than ${formatCurrency(
              x.toFixed(2)
            )})`
        },
        stepHelp:
          "Please provide an estimate of the current mortgage balance you carry."
      },
      {
        label: "How much additional funds are you looking for?",
        type: "amount",
        min: {
          amount: 1,
          error: "Please enter a value above 0"
        },
        max: {
          prevFromIndex: 2,
          prevOfPrevFromIndex: 1,
          amount: (x, prev, prevOfPrev = 0) => (prevOfPrev - prev) * 0.8,
          error: (x) =>
            `The sum of your remaining mortgage balance and the additional amount you want to borrow must be less than 80% of your propertys value. (Less than ${formatCurrency(
              x.toFixed(2)
            )})`
        }
      }
    ]
  },
  {
    name: "Finance And Credit",
    questions: [
      {
        label: "What is your Annual Household Income?",
        type: "amount"
      },
      {
        label: "How is your Credit Score?",
        type: "options",
        buttons: [
          "Excellent (800-720)",
          "Great (720- 680)",
          "Good (680-600)",
          "Fair (Below 600)"
        ]
      }
    ]
  },
  {
    name: "Almost There",
    title: "Just a few more questions to go",
    questions: [
      {
        label: "What term do you prefer?",
        type: "options",
        buttons: ["5 Years", "3 Years", "1 Year"]
      },
      {
        label: "What kind of rates do you prefer?",
        type: "options",
        buttons: ["Fixed", "Variable", "Not Sure"],
        stepHelp: (
          <span>
            Monthly payments remain the same with <strong>fixed rate</strong>{" "}
            mortgages. <br />
            <br />
            Mortgage payments may vary slightly every month with{" "}
            <strong>variable rate</strong> mortgages.
          </span>
        )
      }
    ]
  },
  {
    name: "Last Step",
    lastStepText:
      "After comparing more than 40 vendors we found the lowest rates for you.",
    completeText: "Where do we send the quote?",
    isLastStep: true
  },
  {
    name: "Success" // reserved keyword
  }
]
